import { defineStyleConfig } from '@chakra-ui/react'

const Menu = defineStyleConfig({
  baseStyle: {
    list: {
      p: '0px',
      bg: 'none'
    },
    item: {
      p: '0px',
      bg: 'none'
    }
  }
  // variants: {
  //   outline: {
  //     border: '1px solid',
  //     borderColor: 'kurama-primary',
  //     color: 'kurama-primary',
  //     _hover: {
  //       borderColor: 'kurama-secondary',
  //       color: 'kurama-secondary'
  //     }
  //   },
  //   solid: {
  //     bg: 'kurama-primary',
  //     _hover: {
  //       bg: 'kurama-button-hover-bg'
  //     },
  //     _disabled: {
  //       _light: {
  //         color: 'blackAlpha.300',
  //         bg: '#ECECEC'
  //       },
  //       _dark: {
  //         color: 'whiteAlpha.300',
  //         bg: 'kurama-button-disabled-bg'
  //       }
  //     },
  //     color: 'black'
  //   }
  // },
  // // The default size and variant values
  // defaultProps: {
  //   size: 'md',
  //   variant: 'solid'
  // }
})

export default Menu
