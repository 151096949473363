import { ChakraProvider, Box, Flex } from '@chakra-ui/react'
import '@rainbow-me/rainbowkit/styles.css'
import { Outlet } from 'react-router-dom'
import NavHeader from './NavHeader'
import theme from '../theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { config } from './wagmi';

export default function KuramaLayout() {
  const queryClient = new QueryClient()

  return (
    <ChakraProvider theme={theme}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <Box
              minW="1200px"
              sx={{
                '@media screen and (max-width: 1200px)': {
                  w: '100%',
                  minW: 'unset'
                }
              }}
            >
              <NavHeader />
              <Flex justifyContent="center" minH="calc(100vh - 60px)">
                <Outlet></Outlet>
              </Flex>
            </Box>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  )
}
