// theme/index.js
import { extendTheme } from '@chakra-ui/react'
import Input from './components/input'
import Button from './components/button'
import Menu from './components/menu'

// Global style overrides
import styles from './styles'

const overrides = {
  config: {
    initialColorMode: 'dark'
  },
  styles,
  textStyle: {
    number: {
      // fontFamily: 'Noto Sans Mono'
    },
    text: {
      // fontFamily: 'Noto Sans Mono'
    }
  },
  components: {
    Input,
    Button,
    Menu
  },
  semanticTokens: {
    colors: {
      'chakra-body-bg': { _light: '#0A050B', _dark: '#0A050B' },
      'input-text-content': { _light: '#888888', _dark: 'rgba(255,255,255, 0.5)' },
      'kurama-button-disabled-bg': { _light: '', _dark: '#2A404E' },
      'tucana-primary': '#A6DEEB',
      'kurama-card-bg': { _light: '#FFFFFF', _dark: '#030712' },
      'kurama-card-border': { _light: '#A0D2BA', _dark: '#17202D' },
      'kurama-icon-gray': '#fff',
      'kurama-text-content': { _light: '#888888', _dark: 'rgba(255,255,255, 0.5)' },
      'kurama-primary': '#76C283',
      'tucana-menu-bg': { _light: '#FFFFFF', _dark: '#17202D' },
      'tucana-menu-active': { _light: '#F9FCF8', _dark: 'rgba(166,222,235,0.1)' },
      'text_paragraph': { _light: '#fff', _dark: '#fff' },
      orange: {
        100: '#76C283',
        10: 'rgb(255,157,104, 0.1)',
        20: 'rgb(255,157,104, 0.2)',
        30: 'rgb(255,157,104, 0.3)'
      },
      green: {
        100: '#68FFB9',
        200: '#008F4C',
        10: 'rgba(104,255,185,0.1)',
        20: 'rgba(104,255,185,0.2)'
      },
      red: {
        100: '#FF7968',
        10: 'rgba(255,121,104,0.1)'
      },
      whiteAlpha: {
        700: 'rgba(255,255,255,0.7)',
        500: 'rgba(255,255,255,0.5)',
        300: 'rgba(255,255,255,0.3)',
        30: 'rgba(255,255,255,0.03)'
      },
      blackAlpha: {
        700: 'rgba(0, 0, 0, 0.7)',
        500: 'rgba(0,0,0,0.5)',
        300: 'rgba(0,0,0,0.3)'
      }
    }
  }
}

export default extendTheme(overrides)
