import { Box, Flex, Button, Image, Text, VStack, HStack } from '@chakra-ui/react'
import mobileVideoImage from '../assets/images/mobile-video.png'
import mobileVideoH5Image from '../assets/images/mobile-video-h5.png'
import androidImage from '../assets/images/android.svg'
import iphoneImage from '../assets/images/iphone.svg'
import bannerBgImage from '../assets/images/banner-bg.png'
import spaceImage from '../assets/images/space.png'
import point1Image from '../assets/images/point1.svg'
import point2Image from '../assets/images/point2.svg'
import point3Image from '../assets/images/point3.svg'
import point4Image from '../assets/images/point4.svg'
import point5Image from '../assets/images/point5.svg'
import roadImage from '../assets/images/road.png'
import roadH5Image from '../assets/images/road-h5.png'
import roadLineImage from '../assets/images/road-line.png'
import tonImage from '../assets/images/ton.svg'
import binanceImage from '../assets/images/binance.svg'
import metamaskImage from '../assets/images/metamask.svg'
import bitkeepImage from '../assets/images/bitkeep.svg'
import galaxyImage from '../assets/images/galaxy.svg'
import elementImage from '../assets/images/element.svg'
import taskonImage from '../assets/images/taskon.svg'
import jinsecaijingImage from '../assets/images/jinsecaijing.svg'
import coin98Image from '../assets/images/coin98.svg'
import footerLineImage from '../assets/images/footer-line.png'
import youtubeImage from '../assets/images/youtube.svg'
import tiktokImage from '../assets/images/tiktok.svg'
import telegramImage from '../assets/images/telegram.svg'
import twitterImage from '../assets/images/twitter.svg'
// import discordImage from '../assets/images/discord.svg'
import roundImage from '../assets/images/round.svg'
import bannerBgH5Image from '../assets/images/banner-bg-h5.png'
import titleBgImage from '../assets/images/title-bg.png'
import leftBgImage from '../assets/images/left-bg.png'
import rightBgImage from '../assets/images/right-bg.png'
import { Link as ScrollLink } from 'react-scroll'
import { useToast } from '@chakra-ui/react'
import Footer from '../components/Footer';

export default function Home() {
    const toast = useToast();

    const handleTwitterClick = () => {
        window.open('https://x.com/HermesWings_BSC', '_blank');
    };
    // const handleYoutubeClick = () => {
    //     window.open('https://www.youtube.com/@X-Spaces', '_blank');
    // };
    // const handleTiktokClick = () => {
    //     window.open('https://www.tiktok.com/@xs20240601', '_blank');
    // };
    // const handleTelegramClick = () => {
    //     window.open('https://t.me/XSpaceVS', '_blank');
    // };
    // const handleDocsClick = () => {
    //     window.open('https://x-space.gitbook.io/x-space-doc_en', '_blank');
    // };

    const handleClick = () => {
        toast({
            title: "Coming soon",
            status: "info",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box
            w="100%"
            mt={'160px'}
            sx={{
                backgroundColor: '#0A050B',
                '@media screen and (max-width: 1000px)': {
                    mt: '56px',
                    w: '100%',
                    p: '0',
                }
            }}
        >
            <Flex
                id='about'
                sx={{
                    w: '100%',
                    position: 'relative',
                    zIndex: 3,
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-270px',
                        width: '604px',
                        height: '604px',
                        background: `url(${leftBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 2,
                    },
                }}
            >
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Our mission is to create a decentralized platform that seamlessly connects social media and blockchain technology, making it easy and secure for everyone to participate and benefit from the decentralized economy. With Hermes Wings, users are able to transact and interact quickly and securely on the social platforms they use every day, enjoying the benefits and convenience of decentralized technology.
                    Hermes Wings' vision is to become the world's leading decentralized social payment platform, attracting a large number of users and creators to join, and driving the adoption and adoption of blockchain technology. We are committed to providing a superior user experience and rich features through continuous innovation and optimization, and becoming an indispensable digital tool for users.
                </Text>
            </Flex>
            <Footer />
        </Box >
    )
}