import { defineStyleConfig } from '@chakra-ui/react'

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: '500',
    // textTransform: 'uppercase',
    borderRadius: '24px', // <-- border radius is same for all variants and sizes
    _focusVisible: {
      boxShadow: 'none'
    }
  },
  // Two sizes: sm and md
  // sizes: {
  //   sm: {
  //     fontSize: 'sm',
  //     px: 4, // <-- px is short for paddingLeft and paddingRight
  //     py: 3, // <-- py is short for paddingTop and paddingBottom
  //   },
  //   md: {
  //     fontSize: 'md',
  //     px: 6, // <-- these values are tokens from the design system
  //     py: 4, // <-- these values are tokens from the design system
  //   },
  // },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: '1px solid',
      bg: 'kurama-button-cancle-bg',
      borderColor: 'transparent',
      color: 'tucana-primary',
      fontSize: '14px',
      _active: {
        bg: 'tucana-primary',
        color: 'black'
      },
      _light: {
        fontWeight: '500'
      },
      // _dark: {
      //   fontWeight: '400'
      // },
      _disabled: {
        opacity: 0.5,
        _light: {
          color: '#181818',
          borderColor: '#181818',
          bg: 'kurama-button-disabled-bg'
        },
        _dark: {
          color: 'whiteAlpha.500',
          borderColor: 'whiteAlpha.500',
          bg: 'kurama-button-disabled-bg'
        }
      },
      _hover: {
        bg: 'tucana-primary',
        color: '#0C0B1E'
      }
    },
    solid: {
      bg: 'tucana-primary',
      _hover: {
        bg: 'kurama-button-hover-bg'
      },
      _active: {
        bg: 'kurama-button-hover-bg',
        color: '#0C0B1E'
      },
      _disabled: {
        opacity: 1,
        _light: {
          color: '#919191',
          bg: 'kurama-button-disabled-bg'
        },
        _dark: {
          color: '#678E9C',
          bg: 'kurama-button-disabled-bg'
        }
      },
      color: '#0C0B1E'
    },
    link: {
      minW: 'unset',
      textDecoration: 'none'
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'solid'
  }
})

export default Button
