import { createBrowserRouter, Navigate } from 'react-router-dom'
import Layout from '../layout'
import Notfound from '../pages/404'
import Home from '../pages/home'
import PlatformOverview from '../pages/Platform-Overview'
import ProjectBackground from '../pages/Project-Background'
import MissionAndVision from '../pages/Mission-and-Vision'
import TokenAllocation from '../pages/Token-Allocation'
import EcosystemEmpowerment from '../pages/Ecosystem-empowerment'
import Roadmap from '../pages/Development-roadmap'

const router = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/platform-overview',
        element: <PlatformOverview />
      },
      {
        path: '/project-background',
        element: <ProjectBackground />
      },
      {
        path: '/mission-and-vision',
        element: <MissionAndVision />
      },
      {
        path: '/token-allocation',
        element: <TokenAllocation />
      },
      {
        path: '/ecosystem-empowerment',
        element: <EcosystemEmpowerment />
      },
      {
        path: '/development-roadmap',
        element: <Roadmap />
      },
      // {
      //   path: '/',
      //   element: <Navigate to="/Home" />
      // }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/404" />
  },
  {
    path: '/404',
    element: <Notfound />
  }
]

export default createBrowserRouter(router)
