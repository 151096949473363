import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  arbitrum,
  base,
  mainnet,
  optimism,
  polygon,
  sepolia,
} from 'wagmi/chains';

export const config = getDefaultConfig({
  appName: 'x-space',
  projectId: '9c96193e76ddba6423751b2fad12cdb8',
  chains: [
    mainnet,
    // polygon,
    // optimism,
    // arbitrum,
    // base,
    // ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [sepolia] : []),
  ],
});
