import { Box, Flex, Button, Image, Text, VStack, HStack } from '@chakra-ui/react'
import mobileVideoImage from '../assets/images/mobile-video.png'
import mobileVideoH5Image from '../assets/images/mobile-video-h5.png'
import androidImage from '../assets/images/android.svg'
import iphoneImage from '../assets/images/iphone.svg'
import bannerBgImage from '../assets/images/banner-bg.png'
import spaceImage from '../assets/images/space.png'
import point1Image from '../assets/images/point1.svg'
import point2Image from '../assets/images/point2.svg'
import point3Image from '../assets/images/point3.svg'
import point4Image from '../assets/images/point4.svg'
import point5Image from '../assets/images/point5.svg'
import roadImage from '../assets/images/road.png'
import roadH5Image from '../assets/images/road-h5.png'
import roadLineImage from '../assets/images/road-line.png'
import tonImage from '../assets/images/ton.svg'
import binanceImage from '../assets/images/binance.svg'
import metamaskImage from '../assets/images/metamask.svg'
import bitkeepImage from '../assets/images/bitkeep.svg'
import galaxyImage from '../assets/images/galaxy.svg'
import elementImage from '../assets/images/element.svg'
import taskonImage from '../assets/images/taskon.svg'
import jinsecaijingImage from '../assets/images/jinsecaijing.svg'
import coin98Image from '../assets/images/coin98.svg'
import footerLineImage from '../assets/images/footer-line.png'
import youtubeImage from '../assets/images/youtube.svg'
import tiktokImage from '../assets/images/tiktok.svg'
import telegramImage from '../assets/images/telegram.svg'
import twitterImage from '../assets/images/twitter.svg'
// import discordImage from '../assets/images/discord.svg'
import roundImage from '../assets/images/round.svg'
import bannerBgH5Image from '../assets/images/banner-bg-h5.png'
import titleBgImage from '../assets/images/title-bg.png'
import leftBgImage from '../assets/images/left-bg.png'
import rightBgImage from '../assets/images/right-bg.png'
import { Link as ScrollLink } from 'react-scroll'
import { useToast } from '@chakra-ui/react'
import Footer from '../components/Footer';

export default function Home() {

    return (
        <Box
            w="100%"
            mt={'160px'}
            sx={{
                backgroundColor: '#0A050B',
                '@media screen and (max-width: 1000px)': {
                    mt: '56px',
                    w: '100%',
                    p: '0',
                }
            }}
        >

            <VStack
                id='about'
                sx={{
                    w: '100%',
                    position: 'relative',
                    zIndex: 3,
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-270px',
                        width: '604px',
                        height: '604px',
                        background: `url(${leftBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 2,
                    },
                }}
            >
                <Text
                    fontSize={'24px'}
                    fontWeight={600}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    textAlign={'center'}
                    sx={{
                        background:
                            "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                    }}
                >
                    Phase 1: Platform Development and Testing
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    2024 Q3 - Q4
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Develop core functions: Complete the development of key functional modules such as Blink integration, basic functions, and data encryption.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Internal testing: Conduct comprehensive functional testing, performance testing, and security testing to ensure the stability and reliability of the platform.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Security audit: Hire a third party to conduct a security audit to find and fix potential security vulnerabilities and ensure the security of the platform.
                </Text>
                <Text
                    fontSize={'24px'}
                    fontWeight={600}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    textAlign={'center'}
                    sx={{
                        background:
                            "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                    }}
                >
                    Phase 2: Initial launch and marketing
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    2025 Q1 - Q2
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Beta version: Invite early adopters to participate in testing, gather feedback, and make adjustments.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Go-to-market campaigns: Launch go-to-market campaigns to engage users through social media, partnerships, and community events. Carry out online competitions and creative solicitation activities to enhance the visibility of the platform.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Community Building: Build and activate a community of users, engage with users through social media and forums, gather feedback and continuously improve the platform.
                </Text>
                <Text
                    fontSize={'24px'}
                    fontWeight={600}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    textAlign={'center'}
                    sx={{
                        background:
                            "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                    }}
                >
                    Phase 3: Function Expansion and Optimization
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    2025 Q3 - Q4
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Optimize platform features: Based on user feedback, continuously optimize platform features and user experience to resolve issues found in beta testing.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Expand support platforms: Expand support for more social media platforms and instant messaging apps to expand user scenarios.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Incentives and incentives: Introduce more incentives and incentives, such as transaction mining and staking rewards, to increase user activity and stickiness.
                </Text>
                <Text
                    fontSize={'24px'}
                    fontWeight={600}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    textAlign={'center'}
                    sx={{
                        background:
                            "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                    }}
                >
                    Phase 4: Global Expansion and Ecosystem Building
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    2026 Q1 - Q2
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Global expansion: Expand the international market, attract global users, carry out localized marketing activities, and enhance the international influence of the platform.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Ecosystem building: Establish the Hermes Wings ecosystem to enable third-party developers and partners to integrate and extend the platform's capabilities. APIs and development kits (SDKs) are available to encourage developers to create new apps and services on the platform.
                </Text>
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'20px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >
                    Decentralized governance: Decentralized voting is introduced to allow HWG holders to participate in the governance of the platform and jointly determine the future development direction of the platform.
                </Text>
            </VStack>
            <Footer/>
        </Box >
    )
}