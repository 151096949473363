import { defineStyleConfig } from '@chakra-ui/react'

const Input = defineStyleConfig({
  baseStyle: {
    addon: {
      fontFamily: 'Noto Sans Mono',
      '::placeholder': {
        fontWeight: '600'
      },
      _light: {
        fontWeight: '600',
        '::placeholder': {
          color: 'input-text-content'
        }
      }
    },
    field: {
      fontFamily: 'Noto Sans Mono',
      '::placeholder': {
        fontWeight: '600'
      },
      _light: {
        fontWeight: '600',
        '::placeholder': {
          color: 'input-text-content'
        }
      }
    }
  },
  variants: {
    unstyled: {
      fontFamily: 'Noto Sans Mono',
      '::placeholder': {
        fontWeight: '600'
      },
      _light: {
        fontWeight: '600',
        '::placeholder': {
          color: 'input-text-content'
        }
      }
    }
  },
  defaultProps: {
    variant: 'unstyled'
  }
})

export default Input
