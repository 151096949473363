import { Box, Flex, Button, Image, Text, VStack, HStack } from '@chakra-ui/react'
import mobileVideoImage from '../assets/images/mobile-video.png'
import mobileVideoH5Image from '../assets/images/mobile-video-h5.png'
import androidImage from '../assets/images/android.svg'
import iphoneImage from '../assets/images/iphone.svg'
import bannerBgImage from '../assets/images/banner-bg.png'
import spaceImage from '../assets/images/space.png'
import point1Image from '../assets/images/point1.svg'
import point2Image from '../assets/images/point2.svg'
import point3Image from '../assets/images/point3.svg'
import point4Image from '../assets/images/point4.svg'
import point5Image from '../assets/images/point5.svg'
import roadImage from '../assets/images/road.png'
import roadH5Image from '../assets/images/road-h5.png'
import roadLineImage from '../assets/images/road-line.png'
import tonImage from '../assets/images/ton.svg'
import binanceImage from '../assets/images/binance.svg'
import metamaskImage from '../assets/images/metamask.svg'
import bitkeepImage from '../assets/images/bitkeep.svg'
import galaxyImage from '../assets/images/galaxy.svg'
import elementImage from '../assets/images/element.svg'
import taskonImage from '../assets/images/taskon.svg'
import jinsecaijingImage from '../assets/images/jinsecaijing.svg'
import coin98Image from '../assets/images/coin98.svg'
import footerLineImage from '../assets/images/footer-line.png'
import youtubeImage from '../assets/images/youtube.svg'
import tiktokImage from '../assets/images/tiktok.svg'
import telegramImage from '../assets/images/telegram.svg'
import twitterImage from '../assets/images/twitter.svg'
// import discordImage from '../assets/images/discord.svg'
import roundImage from '../assets/images/round.svg'
import bannerBgH5Image from '../assets/images/banner-bg-h5.png'
import titleBgImage from '../assets/images/title-bg.png'
import leftBgImage from '../assets/images/left-bg.png'
import rightBgImage from '../assets/images/right-bg.png'
import { Link as ScrollLink } from 'react-scroll'
import { useToast } from '@chakra-ui/react'
import Footer from '../components/Footer';

export default function Home() {
    const toast = useToast();

    const handleTwitterClick = () => {
        window.open('https://x.com/HermesWings_BSC', '_blank');
    };
    // const handleYoutubeClick = () => {
    //     window.open('https://www.youtube.com/@X-Spaces', '_blank');
    // };
    // const handleTiktokClick = () => {
    //     window.open('https://www.tiktok.com/@xs20240601', '_blank');
    // };
    // const handleTelegramClick = () => {
    //     window.open('https://t.me/XSpaceVS', '_blank');
    // };
    // const handleDocsClick = () => {
    //     window.open('https://x-space.gitbook.io/x-space-doc_en', '_blank');
    // };

    const handleClick = () => {
        toast({
            title: "Coming soon",
            status: "info",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box
            w="100%"
            mt={'160px'}
            sx={{
                backgroundColor: '#0A050B',
                '@media screen and (max-width: 1000px)': {
                    mt: '56px',
                    w: '100%',
                    p: '0',
                }
            }}
        >
            {/* <HStack
                w="100%"
                gap="124px"
                justifyContent="space-between"
                alignItems="flex-start"
                mt={'78px'}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: '0',
                        width: '1345px',
                        height: '766px',
                        background: `url(${bannerBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 1,
                        marginRight: '-100px',
                        '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                            marginRight: '-300px',
                        },
                        '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                            marginRight: '-300px',
                        },
                    },
                    '@media screen and (min-width: 1001px) and (max-width: 1919px)': {
                        gap: '80px',
                    },
                    '@media screen and (max-width: 1000px)': {
                        '::before': {
                            display: 'none',
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '32px',
                        gap: '30px',
                    }
                }}>
                <Box
                    position="relative"
                    zIndex={2}
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginTop: '0',
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            alignItems: 'center',
                        }
                    }}
                >
                    <VStack gap="16px" alignItems="flex-start" w={'727px'}
                        sx={{
                            '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                                width: '627px',
                            },
                            '@media screen and (min-width: 1366px) and (max-width: 1512px)': {
                                width: '627px',
                            },
                            '@media screen and (max-width: 1000px)': {
                                w: '270px',
                                gap: '0',
                                alignItems: 'center',
                                textAlign: 'center',
                            }
                        }}
                    >
                        <Text fontSize={'60px'} fontWeight={400}
                            sx={{
                                '@media screen and (min-width: 1100px) and (max-width: 1512px)': {
                                    fontSize: '56px',
                                },
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '26px',
                                    fontWeight: 400,
                                }
                            }}
                        >Decentralized social payment platform</Text>
                        <Text fontSize={'60px'} fontWeight={400}
                            sx={{
                                '@media screen and (min-width: 1100px) and (max-width: 1512px)': {
                                    fontSize: '56px',
                                },
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '26px',
                                    fontWeight: 400,
                                }
                            }}
                        >based on the BSC blockchain and B-links technology.</Text>
                    </VStack>
                </Box>
                <Box
                    position="relative"
                    zIndex={2}
                    width={'747px'}
                    sx={{
                        '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                            width: '547px',
                        },
                        '@media screen and (min-width: 1366px) and (max-width: 1512px)': {
                            width: '547px',
                        },
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            pr: '32px',
                            '::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-10px',
                                right: '0',
                                width: '100%',
                                height: '210px',
                                background: `url(${bannerBgH5Image}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                zIndex: -1,
                                m: '0',
                            },
                        }
                    }}
                >
                    <Image src={mobileVideoImage} w={'515px'} h={'581px'}
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                display: 'none'
                            }
                        }} />
                    <Image
                        src={mobileVideoH5Image}
                        alt="logo"
                        display="none"
                        w="263px"
                        h="297px"
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                display: 'block'
                            }
                        }}
                    />
                </Box>
            </HStack > */}
            <Flex
                id='about'
                sx={{
                    w: '100%',
                    position: 'relative',
                    zIndex: 3,
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-270px',
                        width: '604px',
                        height: '604px',
                        background: `url(${leftBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 2,
                    },
                }}
            >
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >Hermes Wings is a decentralized social payment platform based on the Solana blockchain and Blinks technology. With Blink links embedded in social media posts, chat messages, or web pages on the platform, users can quickly complete a variety of transactions and interactions without leaving their current app.
                Hermes Wings is inspired by the ancient Greece mythology of Hermes, a messenger of the gods and a symbol of speed, communication, and travel. Hermes Wings provides a one-stop Blinks distribution, market data dashboard, creator toolkit and user incentives to provide users with an efficient, convenient and secure experience.
                </Text>
            </Flex>
            <Footer />
        </Box >
    )
}