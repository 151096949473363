import { Box, Flex, Button, Image, Text, VStack, HStack } from '@chakra-ui/react'
import mobileVideoImage from '../assets/images/mobile-video.png'
import mobileVideoH5Image from '../assets/images/mobile-video-h5.png'
import bannerImage from '../assets/images/banner.webp'
import androidImage from '../assets/images/android.svg'
import iphoneImage from '../assets/images/iphone.svg'
import bannerBgImage from '../assets/images/banner-bg.png'
import spaceImage from '../assets/images/space.png'
import point1Image from '../assets/images/point1.svg'
import point2Image from '../assets/images/point2.svg'
import point3Image from '../assets/images/point3.svg'
import point4Image from '../assets/images/point4.svg'
import point5Image from '../assets/images/point5.svg'
import roadImage from '../assets/images/road.png'
import roadH5Image from '../assets/images/road-h5.png'
import roadLineImage from '../assets/images/road-line.png'
import tonImage from '../assets/images/ton.svg'
import binanceImage from '../assets/images/binance.svg'
import metamaskImage from '../assets/images/metamask.svg'
import bitkeepImage from '../assets/images/bitkeep.svg'
import galaxyImage from '../assets/images/galaxy.svg'
import elementImage from '../assets/images/element.svg'
import taskonImage from '../assets/images/taskon.svg'
import jinsecaijingImage from '../assets/images/jinsecaijing.svg'
import coin98Image from '../assets/images/coin98.svg'
import footerLineImage from '../assets/images/footer-line.png'
import youtubeImage from '../assets/images/youtube.svg'
import tiktokImage from '../assets/images/tiktok.svg'
import telegramImage from '../assets/images/telegram.svg'
import twitterImage from '../assets/images/twitter.svg'
// import discordImage from '../assets/images/discord.svg'
import roundImage from '../assets/images/round.svg'
import bannerBgH5Image from '../assets/images/banner-bg-h5.png'
import titleBgImage from '../assets/images/title-bg.png'
import leftBgImage from '../assets/images/left-bg.png'
import rightBgImage from '../assets/images/right-bg.png'
import { Link as ScrollLink } from 'react-scroll'
import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react';
import Footer from '../components/Footer';

export default function Home() {
    const toast = useToast();

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const handleClick = () => {
        toast({
            title: "Coming soon",
            status: "info",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box
            w="100%"
            mt={'160px'}
            sx={{
                backgroundColor: '#0A050B',
                '@media screen and (max-width: 1000px)': {
                    mt: '56px',
                    w: '100%',
                    p: '0',
                }
            }}
        >
            <HStack
                w="100%"
                gap="124px"
                justifyContent="space-between"
                alignItems="flex-start"
                mt={'78px'}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        right: '0',
                        width: '1345px',
                        height: '766px',
                        background: `url(${bannerBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 1,
                        marginRight: '-100px',
                        '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                            marginRight: '-300px',
                        },
                        '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                            marginRight: '-300px',
                        },
                    },
                    '@media screen and (min-width: 1001px) and (max-width: 1919px)': {
                        gap: '80px',
                    },
                    '@media screen and (max-width: 1000px)': {
                        '::before': {
                            display: 'none',
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '32px',
                        gap: '30px',
                    }
                }}>
                <Box
                    position="relative"
                    zIndex={2}
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginTop: '0',
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            alignItems: 'center',
                        }
                    }}
                >
                    <VStack gap="16px" alignItems="flex-start" w={'727px'}
                        sx={{
                            '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                                width: '627px',
                            },
                            '@media screen and (min-width: 1366px) and (max-width: 1512px)': {
                                width: '627px',
                            },
                            '@media screen and (max-width: 1000px)': {
                                w: '270px',
                                gap: '0',
                                alignItems: 'center',
                                textAlign: 'center',
                            }
                        }}
                    >
                        <Text fontSize={'60px'} fontWeight={400}
                            sx={{
                                background:
                                    "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
                                WebkitBackgroundClip: "text",
                                color: "transparent",
                                '@media screen and (min-width: 1100px) and (max-width: 1512px)': {
                                    fontSize: '56px',
                                },
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '26px',
                                    fontWeight: 400,
                                }
                            }}
                        >Decentralized social payment platform</Text>
                        <Text fontSize={'60px'} fontWeight={400}
                            sx={{
                                background:
                                    "var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))",
                                WebkitBackgroundClip: "text",
                                color: "transparent",
                                '@media screen and (min-width: 1100px) and (max-width: 1512px)': {
                                    fontSize: '56px',
                                },
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '26px',
                                    fontWeight: 400,
                                }
                            }}
                        >based on the BSC blockchain and B-links technology.</Text>
                    </VStack>
                    {/* <HStack gap="44px" mt={'140px'}
                        sx={{
                            width: '727px',
                            '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                                width: '627px',
                            },
                            '@media screen and (min-width: 1366px) and (max-width: 1440px)': {
                                width: '627px',
                            },
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                flexDirection: 'column-reverse',
                                gap: '13px',
                                mt: '13px',
                            }
                        }}
                    >
                        <Button
                            className='hstackImg'
                            background={'var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))'}
                            boxShadow={'0px 4px 4px 0px #2B59D2'}
                            borderRadius={'100px'}
                            w={'294px'}
                            h={'81px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '140px',
                                    h: '35px',
                                }
                            }}
                            onClick={() => window.open('https://mapi.xspace.land/h5/download', '_blank')}
                        >
                            <Image src={androidImage} w={'37px'} h={'43.463px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '15.693px',
                                        height: '18.435px',
                                    }
                                }}
                            />
                            <Text fontSize={'40px'} fontWeight={400} color={'#fff'} ml={'20px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                    }
                                }}
                            >Android</Text>
                        </Button>
                        <Button
                            className='hstackImg'
                            background={'linear-gradient(90deg, #FD36D4 0%, #B961FF 53.5%, #01F8FF 100%)'}
                            boxShadow={'0px 4px 4px 0px #3552D0'}
                            borderRadius={'100px'}
                            w={'294px'}
                            h={'81px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '140px',
                                    h: '35px',
                                }
                            }}
                            onClick={() => window.open('https://fir.xcxwo.com/vqjeyzhr', '_blank')}
                        >
                            <Image src={iphoneImage} w={'37.264px'} h={'42.476px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '16.067px',
                                        height: '18.315px',
                                    }
                                }}
                            />
                            <Text fontSize={'40px'} fontWeight={400} color={'#fff'} ml={'20px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',

                                    }
                                }}
                            >IPhone</Text>
                        </Button>
                    </HStack> */}
                </Box>
                <Box
                    position="relative"
                    zIndex={2}
                    width={'747px'}
                    sx={{
                        '@media screen and (min-width: 1024px) and (max-width: 1365px)': {
                            width: '547px',
                        },
                        '@media screen and (min-width: 1366px) and (max-width: 1512px)': {
                            width: '547px',
                        },
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            pr: '32px',
                            '::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-10px',
                                right: '0',
                                width: '100%',
                                height: '210px',
                                background: `url(${bannerBgH5Image}) 0 0 no-repeat`,
                                backgroundSize: '100% 100%',
                                zIndex: -1,
                                m: '0',
                            },
                        }
                    }}
                >
                    <Image src={mobileVideoImage} w={'515px'} h={'581px'}
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                display: 'none'
                            }
                        }} />
                    <Image
                        src={mobileVideoH5Image}
                        alt="logo"
                        display="none"
                        w="263px"
                        h="297px"
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                display: 'block'
                            }
                        }}
                    />
                </Box>
            </HStack >
            <Flex
                id='about'
                sx={{
                    w: '100%',
                    position: 'relative',
                    zIndex: 3,
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-270px',
                        width: '604px',
                        height: '604px',
                        background: `url(${leftBgImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        zIndex: 2,
                    },
                }}
            >
                <Text
                    w={'1235px'}
                    fontSize={'22px'}
                    fontWeight={400}
                    lineHeight={'33px'}
                    margin={'100px auto 0'}
                    sx={{
                        '@media screen and (max-width: 1000px)': {
                            w: '100%',
                            fontSize: '12px',
                            lineHeight: '18px',
                            margin: '20px auto 0',
                            p: '0 30px',
                        }
                    }}
                >Hermes Wings is a decentralized social payment platform based on the BSC blockchain and B-links technology. Through Blink links embedded in social media posts, chat messages, or web pages, users can quickly complete various transactions and interactions without leaving their current application. Inspired by Hermes, the messenger god in Greek mythology symbolizing speed, communication, and travel, Hermes Wings offers an all-in-one B-links issuance tool, market data dashboard, creator toolkit, and user incentives, providing an efficient, convenient, and secure user experience.</Text>
            </Flex>
            <Box id='UniqueAdvantages'>
                <Flex
                    sx={{
                        background: `url(${spaceImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        width: '494.025px',
                        height: '173.45px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '65px auto 0',
                        '@media screen and (max-width: 1000px)': {
                            w: '181.975px',
                            h: '67px',
                            margin: '20px auto 0',
                        }
                    }}
                >
                    <Text
                        sx={{
                            fontSize: '46px',
                            fontWeight: 400,
                            color: '#fff',
                            pt: '30px',
                            '@media screen and (max-width: 1000px)': {
                                fontSize: '14px',
                            }
                        }}
                    >Unique Advantages</Text>
                </Flex>
                <VStack
                    sx={{
                        margin: '0 auto',
                        gap: '50px',
                        position: 'relative',
                        overflow: 'hidden',
                        p: '135px 0 95px 0',
                        '::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            right: '-302px',
                            width: '604px',
                            height: '604px',
                            background: `url(${rightBgImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                            zIndex: 1,
                        },
                        '@media screen and (max-width: 1000px)': {
                            p: '10px 14px 35px 14px',
                            gap: '20px',
                        }
                    }}
                >
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack gap={'20px'} w={'220px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point1Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '35px',
                                        h: '35px',
                                    }
                                }}
                            />
                            <Text fontSize={'28px'} fontWeight={400} lineHeight={'42px'} textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >All-in-One Link Generation Tool</Text>
                        </VStack>
                        <VStack gap={'15px'} width={'721px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '100%',
                                }
                            }}
                        >
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                {/* <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                </Text> */}
                                Hermes Wings provides a rich set of generation tools, allowing users to easily create personalized Token/NFT, donation, and reward links, decentralized voting, creator economy, and prediction markets. The integration of these tools enables users to participate in blockchain activities without needing a technical background.
                            </Flex>
                            {/* <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Live Streaming：
                                </Text>
                                Users can conduct real-time live broadcasts and interact with their audience. During live streams, viewers can participate by sending virtual gifts and real-time comments, enhancing the interactive experience.
                            </Flex> */}
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack gap={'20px'} w={'220px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}>
                            <Image src={point2Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '32.366px',
                                        h: '39.382px',
                                    }
                                }}
                            />
                            <Text fontSize={'28px'} fontWeight={400} lineHeight={'42px'} textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >Real-Time Trading and Management</Text>
                        </VStack>
                        <VStack gap={'15px'} width={'721px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '100%',
                                }
                            }}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                {/* <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Data Privacy and Security：
                                </Text> */}
                                Through the B-links aggregated information dashboard, users can view and manage all market transactions and activities in real time, providing real-time activity status updates to help users better understand market dynamics.
                            </Flex>
                            {/* <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Smart Contracts：
                                </Text>
                                Smart contracts automatically enforce platform rules and reward distribution, ensuring each user's rights and the transparency of platform operations, automating transactions between users, reducing human intervention and errors.
                            </Flex> */}
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack
                            gap={'20px'}
                            w={'220px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point3Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '39.463px',
                                        h: '35.453px',
                                    }
                                }}
                            />
                            <Text
                                fontSize={'28px'}
                                fontWeight={400}
                                lineHeight={'42px'}
                                textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >User Incentive Mechanism</Text>
                        </VStack>
                        <VStack gap={'30px'} width={'721px'}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                {/* <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Transparent Governance：
                                </Text> */}
                                Hermes Wings can set up additional social invitation incentives, encouraging users to actively share and participate in activities, bringing additional social network value to users.
                            </Flex>
                            {/* <Flex align="flex-start"
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                <Text fontSize="24px" fontWeight="400" color="#fff"
                                    sx={{
                                        display: 'contents',
                                        '@media screen and (max-width: 1000px)': {
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    Incentive Mechanism：
                                </Text>
                                By actively participating in community governance and proposals, users can earn additional token rewards, encouraging them to contribute to the platform's growth and development.
                            </Flex> */}
                        </VStack>
                    </HStack>
                    <HStack
                        gap={'86px'}
                        className="fadeInUp hstack"
                        sx={{
                            width: '1300px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '171px',
                            border: '1px solid var(--Linear, #F8C9F4)',
                            background: 'rgba(30, 30, 30, 0.30)',
                            boxShadow: '0px 12px 50px 0px rgba(137, 82, 255, 0.20)',
                            backdropFilter: 'blur(15px)',
                            padding: '40px 0',
                            '@media screen and (max-width: 1000px)': {
                                w: '100%',
                                borderRadius: '20px',
                                padding: '16px 14px',
                                gap: '10px',
                            }
                        }}
                    >
                        <VStack
                            gap={'20px'}
                            w={'250px'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    w: '80px',
                                }
                            }}
                        >
                            <Image src={point4Image} w={'111.112px'} h={'111.101px'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        w: '44px',
                                        h: '44px',
                                    }
                                }}
                            />
                            <Text
                                fontSize={'28px'}
                                fontWeight={400}
                                lineHeight={'42px'}
                                textAlign={'center'}
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                    }
                                }}
                            >Efficient and Secure Trading Experience</Text>
                        </VStack>
                        <VStack gap={'30px'} width={'721px'}>
                            <Flex
                                sx={{
                                    alignItems: "flex-start",
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    '@media screen and (max-width: 1000px)': {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                Leveraging BSC's high performance and low cost, Hermes Wings provides users with a fast, secure, and low-cost trading experience, solving efficiency and security issues of traditional platforms.
                            </Flex>
                        </VStack>
                    </HStack>
                </VStack>
            </Box>
            <Box>
                <Flex
                    sx={{
                        width: '100%',
                        mt: '135px',
                        '@media screen and (max-width: 1000px)': {
                            mt: '14px',
                        }
                    }}
                >
                    <Flex
                        sx={{
                            fontSize: '46px',
                            fontWeight: 400,
                            background: `url(${spaceImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '494.025px',
                            height: '173.45px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto',
                            '@media screen and (max-width: 1000px)': {
                                width: '195.975px',
                                height: '68.806px',
                            }
                        }}
                    >
                        <Text
                            sx={{
                                fontSize: '46px',
                                fontWeight: 400,
                                color: '#fff',
                                pt: '30px',
                                '@media screen and (max-width: 1000px)': {
                                    fontSize: '14px',
                                }
                            }}
                        >Partners</Text>
                    </Flex>
                </Flex>
                <Box
                    sx={{
                        m: '80px 0 315px 0',
                        '@media screen and (max-width: 1000px)': {
                            m: '20px 0 0 0',
                        }
                    }}
                >
                    <VStack gap={'100px'}
                        sx={{
                            '@media screen and (max-width: 1000px)': {
                                gap: '20px',
                                p: '0 30px',
                            }
                        }}
                    >
                        <HStack w={'1337px'} justifyContent={'space-between'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    width: '100%',
                                }
                            }}
                        >
                            <Image src={tonImage} w={'245px'} h={'99px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '60px',
                                        height: '24px',
                                    }
                                }}
                            />
                            <Image src={binanceImage} w={'414px'} h={'82px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '98.459px',
                                        height: '19px',
                                    }
                                }}
                            />
                            <Image src={metamaskImage} w={'400.115px'} h={'77.193px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '96px',
                                        height: '18px',
                                    }
                                }}
                            />
                        </HStack>
                        <HStack w={'1337px'} justifyContent={'space-between'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    width: '100%',
                                }
                            }}
                        >
                            <Image src={bitkeepImage} w={'319.791px'} h={'105.68px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '76px',
                                        height: '25px',
                                    }
                                }}
                            />
                            <Image src={galaxyImage} w={'384.972px;'} h={'97.913px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '92px',
                                        height: '23px',
                                    }
                                }}
                            />
                            <Image src={elementImage} w={'413.627px;'} h={'76.262px;'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '112px',
                                        height: '20px',
                                    }
                                }}
                            />
                        </HStack>
                        <HStack w={'1337px'} justifyContent={'space-between'}
                            sx={{
                                '@media screen and (max-width: 1000px)': {
                                    width: '100%',
                                }
                            }}
                        >
                            <Image src={taskonImage} w={'320px'} h={'91px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '77px',
                                        height: '22px',
                                    }
                                }}
                            />
                            <Image src={jinsecaijingImage} w={'353px'} h={'87px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '85px',
                                        height: '21px',
                                    }
                                }}
                            />
                            <Image src={coin98Image} w={'369px'} h={'94px'}
                                className='hstackImg'
                                sx={{
                                    '@media screen and (max-width: 1000px)': {
                                        width: '88px',
                                        height: '23px',
                                    }
                                }}
                            />
                        </HStack>
                    </VStack>
                </Box>
            </Box>
            <Footer />
        </Box >
    )
}