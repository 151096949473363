import { Box, Flex, Image, Text, VStack, HStack, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import footerLineImage from '../assets/images/footer-line.png';
import spaceImage from '../assets/images/space.png';
import { Link as ScrollLink } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleTwitterClick = () => {
        window.open('https://x.com/HermesWings_BSC', '_blank');
    };
    const handleTelegramClick = () => {
        window.open('https://t.me/Hermes_Wings', '_blank');
    };
    const handleMediumClick = () => {
        window.open('https://medium.com/@HermesWings', '_blank');
    };

    const handleNavigation = (path: string) => {
        navigate(path);
        window.scrollTo(0, 0);
    };
    const handleMenuItemClick = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                    mt: '135px',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '3px',
                        background: `url(${footerLineImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                    },
                    '@media screen and (max-width: 1000px)': {
                        display: 'none',
                    }
                }}
            >
                <HStack
                    sx={{
                        m: '0 auto',
                        width: '1337px',
                        p: '80px 0',
                        justifyContent: 'space-between',
                    }}
                >
                    <VStack gap={'30px'}>
                        {/* <ScrollLink
                            to="about"
                            smooth={true}
                            duration={500}
                            offset={-200}
                            className='menu-item'
                        >
                            <Text fontSize="18px" fontWeight="400" color="#fff">About</Text>
                        </ScrollLink> */}
                        <RouterLink to="/platform-overview" className='menu-item' onClick={handleMenuItemClick}>
                            About
                        </RouterLink>
                        {location.pathname === '/' ? (
                            <ScrollLink
                                to="UniqueAdvantages"
                                smooth={true}
                                duration={500}
                                offset={-180}
                                className='menu-item'
                                onClick={handleMenuItemClick}
                            >
                                Unique Advantages
                            </ScrollLink>
                        ) : (
                            <RouterLink to="/#UniqueAdvantages" className='menu-item' onClick={handleMenuItemClick}>
                                Unique Advantages
                            </RouterLink>
                        )}
                    </VStack>
                    <VStack gap={'30px'}>
                        <Text fontSize="18px" fontWeight="400" color="#fff" cursor={'pointer'} onClick={() => handleNavigation('/token-allocation')}>Token Economics</Text>
                        <Text fontSize="18px" fontWeight="400" color="#fff" cursor={'pointer'} onClick={() => handleNavigation('/development-roadmap')}>Roadmap</Text>
                    </VStack>
                    <VStack gap={'30px'} alignItems={'flex-start'}>
                        <Text fontSize="18px" fontWeight="400" color="#fff">Communities</Text>
                        <HStack gap={'20px'}>
                            <Button fontSize="18px" fontWeight="400" variant='link' onClick={handleTelegramClick}>Telegram</Button>
                            <Button fontSize="18px" fontWeight="400" variant='link' onClick={handleTwitterClick}>Twitter</Button>
                            <Button fontSize="18px" fontWeight="400" variant='link' onClick={handleMediumClick}>Medium</Button>
                        </HStack>
                    </VStack>
                </HStack>
                <Text fontSize="20px" fontWeight="400" color="#fff" textAlign={'center'} mb={'80px'}>@2024 Hermes Wings All rights reserved.</Text>
            </Box>
            <Box
                sx={{
                    display: 'none',
                    '@media screen and (max-width: 1000px)': {
                        display: 'block',
                    }
                }}
            >
                <Flex
                    sx={{
                        background: `url(${spaceImage}) 0 0 no-repeat`,
                        backgroundSize: '100% 100%',
                        width: '195.975px;',
                        height: '68.806px;',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '35px auto 20px',
                    }}
                >
                    <Text
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#fff',
                            pt: '10px',
                        }}
                    >Communities</Text>
                </Flex>
                <HStack
                    gap={'0'}
                    justifyContent={'space-between'}
                    sx={{
                        position: 'relative',
                        m: '0 15px',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: '-10px',
                            left: '0',
                            width: '100%',
                            height: '1px',
                            background: `url(${footerLineImage}) 0 0 no-repeat`,
                            backgroundSize: '100% 100%',
                        },
                    }}
                >
                    <Button fontSize="18px" fontWeight="400" variant='link' onClick={handleTelegramClick}>Telegram</Button>
                    <Button fontSize="18px" fontWeight="400" variant='link' onClick={handleTwitterClick}>Twitter</Button>
                    <Button fontSize="18px" fontWeight="400" variant='link' onClick={handleMediumClick}>Medium</Button>
                </HStack>
                <Text
                    sx={{
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#fff',
                        textAlign: 'center',
                        m: '20px 0 60px 0',
                    }}
                >@2024 Hermes Wings All rights reserved.</Text>
            </Box>
        </>
    );
};

export default Footer;