import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Text,
  Button,
  HTMLChakraProps,
  Flex,
  Image
} from '@chakra-ui/react'
import { useState } from 'react'
import downArrowImage from '../assets/images/down-arrow.svg'
import downArrowWhiteImage from '../assets/images/down-arrow-white.svg'
import menuBgImage from '../assets/images/menu-bg.png'

interface NavMenuListItem {
  text: string
  icon?: string
  link?: string
  type?: 'reactRouterLink' | '' // 空为a标签
  itemIsActive?: boolean
  svgxlinkHref?: {
    xlinkHref: string
    svgW?: string
    svgH?: string
    boxW?: string
    boxH?: string
    variant?: 'solid' | 'gray' | 'rectangle' | ''
  }
}
interface NavMenuProps extends HTMLChakraProps<'div'> {
  title: string
  list: NavMenuListItem[]
  isActive?: boolean
  current?: string
  hoverOpenHeightH5?: string
}
export default function NavMenu(props: NavMenuProps) {
  const { title, list, isActive, current, hoverOpenHeightH5, ...rest } = props
  const [isHoverOpen, setIsHoverOpen] = useState(false)
  const handleMouseEnter = (event?: any) => {
    // 冒泡没起作用哦？
    event?.stopPropagation()
    setIsHoverOpen(true)
  }
  const handleMouseLeave = (event?: any) => {
    event?.stopPropagation()
    setIsHoverOpen(false)
  }

  const handleClick = (link: string) => {
    if (!link) return
    window.open(link, '_blank');
  };

  return (
    <Box
      mb="-8px"
      sx={{
        '@media screen and (max-width: 810px)': {
          h: isHoverOpen ? (hoverOpenHeightH5 ? hoverOpenHeightH5 : '140px') : ''
        }
      }}
    >
      <Box pb="8px" onClick={() => setIsHoverOpen(!isHoverOpen)} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
        <Menu id={'more'} isOpen={isHoverOpen} onClose={() => handleMouseLeave()} {...rest}>
          {({ isOpen }) => (
            <>
              <MenuButton
                p="0"
                isActive={isHoverOpen}
                as={Button}
                bg="none"
                _hover={{ bg: 'none', p: { color: 'primary' }, svg: { fill: 'primary' } }}
                _active={{ bg: 'none' }}
              >
                <Center>
                  <Text
                    // fontSize="md"
                    color={isActive ? 'primary' : 'text_paragraph'}
                    position="relative"
                    _before={{
                      content: "''",
                      width: '8px',
                      height: '8px',
                      background: 'primary',
                      borderRadius: '100%',
                      position: 'absolute',
                      left: '50%',
                      bottom: '-14px',
                      ml: '-4px',
                      display: isActive ? 'block' : 'none',
                    }}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    sx={{
                      // background: 'var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))',
                      // WebkitBackgroundClip: 'text',
                      // color: 'transparent',
                      '@media screen and (max-width: 810px)': {
                        _before: {
                          left: '-50%',
                          top: '5px'
                        }
                      }
                    }}
                  >
                    {title}
                  </Text>
                  {
                    <Image
                      transition="transform 0.5s"
                      transform={isHoverOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                      src={isHoverOpen ? downArrowImage : downArrowWhiteImage}
                      ml={'4px'}
                      w={'15px'}
                      h={'8px'}
                      sx={{
                        '@media screen and (max-width: 810px)': {
                          display: 'none'
                        }
                      }}
                    />
                  }
                </Center>
              </MenuButton>
              <MenuList
                p="16px 0"
                sx={{
                  background: `url(${menuBgImage}) 0 0 no-repeat`,
                  backgroundSize: '100% 100%',
                  width: '200px',
                  border: 'none',
                  minW: '139px',
                  alignItems: 'flex-end',
                  position: 'absolute',
                  top: '-56px',
                  left: '-11px',
                  zIndex: '1000',
                  '@media screen and (max-width: 810px)': {
                    bg: 'transparent',
                    boxShadow: 'none',
                    backdropFilter: 'none',
                    border: 'none',
                    ml: '-16px',
                    mt: '-20px'
                  }
                }}
              >
                <Flex justifyContent={'center'} pb={'6px'}>
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      background: 'var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                    }}
                  >{title}</Text>
                  <Image
                    transition="transform 0.5s"
                    transform={isHoverOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                    src={downArrowImage}
                    ml={'4px'}
                  />
                </Flex>
                {list.map((item: NavMenuListItem, index: number) => {
                  return item.type === 'reactRouterLink' ? (
                    <MenuItem
                      key={`navMenu${index}`}
                      as={ReactRouterLink}
                      to={item.link}
                      color={current === item.text ? 'primary' : 'text_paragraph'}
                      m="12px 4px"
                      w={'auto'}
                      _hover={{
                        background: 'var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        p: {
                          color: 'primary'
                        },
                        svg: {
                          fill: 'primary'
                        }
                      }}
                    >
                      <Text
                        sx={{
                          fontSize: '14px',
                          fontWeight: '400',
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >{item.text}</Text>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={`navMenu${index}`}
                      as="a"
                      // href={item.link}
                      target="_blank"
                      color={current === item.text ? 'primary' : 'text_paragraph'}
                      m="12px 4px"
                      w={'auto'}
                      _hover={{
                        background: 'var(--Linear, linear-gradient(62deg, #F8C9F4 5.52%, #F7BFF0 11%, #F192DD 22.57%, #6330C9 56.51%, #1E62D3 80.28%))',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        p: {
                          color: 'primary'
                        },
                        svg: {
                          fill: 'primary'
                        }
                      }}
                      onClick={() => handleClick(item.link!)}
                    >
                      <Text
                        sx={{
                          fontSize: '14px',
                          fontWeight: '400',
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >{item.text}</Text>
                    </MenuItem>
                  )
                })}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </Box>
  )
}
